import { Datepicker } from 'vanillajs-datepicker'
import Awesomplete from 'awesomplete'

const dateField = Array.from(document.querySelectorAll('input.date'))
const disabledDates = []

dateField.forEach(elem => {
  elem.onclick = function(e) {
    //prevent HTML 5 date picker
    e.preventDefault()
  }
  elem.classList.remove('is-hidden')
  const dataStartDate = elem.getAttribute('data-start-date')
  const dataEndDate = elem.getAttribute('data-end-date')
  const dataDisableWeekends = elem.getAttribute('data-disable-weekends')
  const dataDisableDates = elem.getAttribute('data-disable-dates')
    ? elem.getAttribute('data-disable-dates').split(',')
    : []
  new Datepicker(elem, {
    buttonClass: 'btn',
    format: 'yyyy-mm-dd',
    clearBtn: true,
    weekStart: 1,
    daysOfWeekDisabled: dataDisableWeekends ? [0, 6] : [],
    todayBtn: true,
    datesDisabled: dataDisableDates,
    minDate: dataStartDate ? dataStartDate : '',
    maxDate: dataEndDate ? dataEndDate : '',
  })
})

export const refreshDatepickers = () => {
  const dateField = Array.from(document.querySelectorAll('input.date'))
  dateField.forEach(elem => {
    elem.onclick = function(e) {
      //prevent HTML 5 date picker
      e.preventDefault()
    }
    elem.classList.remove('is-hidden')
    const dataStartDate = elem.getAttribute('data-start-date')
    const dataEndDate = elem.getAttribute('data-end-date')
    const dataDisableWeekends = elem.getAttribute('data-disable-weekends')
    const dataDisableDates = elem.getAttribute('data-disable-dates')
      ? elem.getAttribute('data-disable-dates').split(',')
      : []
    new Datepicker(elem, {
      buttonClass: 'btn',
      format: 'yyyy-mm-dd',
      clearBtn: true,
      weekStart: 1,
      daysOfWeekDisabled: dataDisableWeekends ? [0, 6] : [],
      todayBtn: true,
      datesDisabled: dataDisableDates,
      minDate: dataStartDate ? dataStartDate : '',
      maxDate: dataEndDate ? dataEndDate : '',
    })
  })
}
